/**
 * @name: 商城管理-推荐商品接口文件
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 商城管理-推荐商品接口文件
 * @update: 2024-05-13 11:37
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { IRecommend, IRecommendParams } from "./types";

export const sortProductTreeApi = (params: {productName?: string}) => get('/admin/recommendProduct/create/productTree', params)

export const recommendCreateApi = (data: {ids: string[]}) => postJ('/admin/recommendProduct/create', data)

export const recommendPageApi = (params: IRecommendParams) => get<IPageRes<IRecommend[]>>('/admin/recommendProduct/query', params)

export const recommendModifyStatusApi = (id: string) => postJ('/admin/recommendProduct/modifyStatus', {id})

export const recommendRemoveApi = (ids: string[]) => postJ('/admin/recommendProduct/remove', {ids})
